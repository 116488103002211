// assets
import { IconDashboard, IconSearch, IconTimelineEventExclamation, IconZoomIn  } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconTimelineEventExclamation, IconZoomIn, IconSearch };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'Insights',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'deepDive',
      title: 'Deep Dive',
      type: 'item',
      url: '/deepdive',
      breadcrumbs: false,
      icon: icons.IconSearch,
      external: false
    },
    // {
    //   id: 'Moments',
    //   title: 'Moments',
    //   type: 'item',
    //   url: '/moments',
    //   breadcrumbs: false,
    //   icon: icons.IconTimelineEventExclamation,
    //   external: false
    // },
  ]
};

export default dashboard;
