// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //


const communitypages = {
  id: 'communitues',
  title: 'Community',
  type: 'group',
  children: [
    {
      id: 'communities',
      title: 'Select Communities',
      type: 'item',
      url: '/communities',
    }
   
  ],
}


export default communitypages;
